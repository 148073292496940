@import "scss/_vars";
@import "scss/_mixin";
@import "scss/_fonts";
@import "scss/main";

@import "scss/board";
@import "scss/lobby";
@import "scss/results";
@import "scss/card";
@import "scss/player";
@import "scss/button";
@import "scss/howtoplay";
@import "scss/popup";
@import "scss/menu";
@import "scss/game_settings";

//Base styles
html, body{
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: #fff;
  user-select: none;
  outline: none;
  width: $screen-width;
  height: $screen-height;
  font-family: "SF-Pro-Rounded-Black";
  h1{
    font-family: "SF-Pro-Rounded-Black";
    font-size: 100px;
  }
}

@media (min-width: $scale-screen-threshold) {
  html {
    -webkit-transform-origin: top left;
       -moz-transform-origin: top left;
        -ms-transform-origin: top left;
         -o-transform-origin: top left;
            transform-origin: top left;
    -webkit-transform: scale($scale-factor);
       -moz-transform: scale($scale-factor);
        -ms-transform: scale($scale-factor);
         -o-transform: scale($scale-factor);
            transform: scale($scale-factor);
  }
}

.HIDDEN {
  display: none;
}
