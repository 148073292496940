.board {
  background: $background-color;
  height: 100%;
  text-align: center;
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  &__title{
    text-align: center;
    margin: auto;
    font-size: 80px;
    font-weight: bold;
    color: #ffffff;
    font-family: $black;
    max-width: 650px;
    display: inline-block;
    vertical-align: middle;

    .username {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__title-wrapper {
    width: 100%;
    display: inline-block;
  }
  &__sidebar{
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
  }
}
