.how-to-play {
  height: 100%;
  width: 100%;
  background: $background-color-splash;
  box-sizing: border-box;
  text-align: center;

  &.hidden {
    display: none!important;
  }

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  .page {
    font-family: $regular;
    font-weight: normal;
    font-size: 22pt;
    margin: 0 auto;
    width: 85%;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  .button-rounded {
    width: $button-menu-size;
    height: $button-menu-size;
    position: absolute;
    z-index: $over-the-top;
    top: 15px;
    left: 15px;
    margin-top: 0px;

    &:after {
      height: $button-menu-size;
      width: $button-menu-size;
    }
  }


  .how-to {
    z-index: $foreground;

    &__title {
      font-family: $bold;
      font-size: 56px;
      padding-bottom: 40px;
      position: relative;
      z-index: $foreground;

      &.logo {
        padding-bottom: 80px;
        padding-top: 0;
      }

      &_index {
        z-index: $foreground;
      }
    }

    &__box-wrap {
      position: absolute;
      left: 50%;
      top: -22px; // pawa
      z-index: $hidden;
      transform: translate(-50%, 0);
      padding-left: 60px;
    }

    &__box {
      width: 80px;
      height: 120px;
      border-radius: 20px;
      opacity: 0.9;
      position: relative;
      display: inline-block;

      &.one {
        /* fill/scarlet */
        left: 0;
        background: #D51F67;
        transform: rotate(-10deg);
      }

      &.two {
        /* fill/scarlet */
        left: -20px;
        background: #A24FDF;
        transform: rotate(30deg);
        z-index: 10;
        opacity: 0.7;
      }

      &.three {
        /* fill/scarlet */
        left: -40px;
        background: #FA7719;
        transform: rotate(-10deg);
      }

      &.four {
        /* fill/scarlet */
        left: -60px;
        background: #00A4FF;
        transform: rotate(14deg);
        opacity: 0.8;
      }

    }

    &__card_result {
      width: 345px;
      height: 475px;
      background-image: url('../img/voted_card_75.png');
      background-size: 345px 475px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 50px;
    }

    &__desc {
      font-family: $regular;
      font-size: 28px;
      max-width: 87%;
      margin: 0 auto 40px;
      line-height: 40px;
      span {
        font-family: $regular;
        color: yellow;
      }
      &.inline {
        display: inline-block;
        width: 50%;
        max-width: 50%;
        vertical-align: middle;
      }
    }

    &__desc-wrap {
      width: 590px;
      margin-top: 100px;

      div {
        display: block;
        width: 100%;
        max-width: 90%;
      }
    }

    &__phone {



      &__en {
        position: absolute;
        right: 170px;
        top: 100px;
        width: 372px;
        height: 620px;
        background: url("../img/phone_img_75_en.png");
        -webkit-background-size: 372px 620px;
        background-size: 372px 620px;
      }

      &__ru {
        position: absolute;
        right: 170px;
        top: 100px;
        width: 372px;
        height: 620px;
        background: url("../img/phone_img_75_en.png");
        -webkit-background-size: 372px 620px;
        background-size: 372px 620px;
      }

      &__ua {
        position: absolute;
        right: 170px;
        top: 100px;
        width: 372px;
        height: 620px;
        background: url("../img/phone_img_75_en.png");
        -webkit-background-size: 372px 620px;
        background-size: 372px 620px;
      }
    }



    &__card-img {
      position: relative;
      height: 260px;
    }

    &__cards-wrap {
      white-space: nowrap;
      width: 85%;
      transform: translate(-40px, 0);
      margin: 0 auto;
      position: absolute;
      left: 5%;
    }

    &__score {
      width: 80px;
      margin: 0 auto;
      height: 125px;
      position:relative;
    }

    &__score-view {
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      background-color: #DFC00C;
      line-height: 80px;
      font-size: 36px;
      font-family: $regular;
      opacity: 1;
      position: absolute;
      left: 0;
      &.three {
        background-color: #FA7719;
      }

      &.one {
        background: #FF91B6;
      }

      &.two {
        background: #27C590;
      }

      &#bubble-0 {
        transform: translate(-165%, 0);
      }
      &#bubble-3 {
        transform: translate(-55%, 0);
      }

      &#bubble-1 {
        transform: translate(55%, 0);
      }
      &#bubble-2 {
        transform: translate(165%, 0);
      }
    }
  }
  .game-over{
    height: 500px;
    width: 700px;
  }
  .player-hist{
    width: 100%;
    padding-top: 0;
    height: 300px;
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    &__item {
      opacity: 1;
      background-color: #1968C9;
      &:nth-child(1) {
        width: 670px;
        .player__img {
          background: #00A3FF url('../img/girafa.png') 0 0 no-repeat;
        }
      }

      &:nth-child(2) {
        width: 920px;
        .player__img {
          background: #F0CD04 url('../img/elephant.png') 0 0 no-repeat;
        }
      }

      &:nth-child(3) {
        width: 760px;
        .player__img {
          background: #FA7719 url('../img/ganco.png') 0 0 no-repeat;
        }
      }
    }
  }

}


  .bullets-wrapper {
    position: absolute;
    right: 40px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .bullet {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #FFF;
      opacity: 0.3;
      margin-bottom: 23px;
      transition: opacity .3s;

      &.active {
        opacity: 1;
      }
    }
  }
