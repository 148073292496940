.cards-hidden-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -140px;
  .card-placeholder{
    position: absolute;
    top: 140px;
    width: 280px;
    height: 427px;
    margin: 0;
  }
}

.card-placeholder {
  width: 160px;
  height: 240px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  vertical-align: middle;
  .left{
    transform: rotate(-1deg);
  }
  .right{
    transform: rotate(2deg);
  }
  &.selected {
    &:after{
      content: '';
      width: 186px;
      height: 276px;
      border: 4px solid yellow;
      border-radius: 20px;
      position: absolute;
      left: -7px;
      top: -7px;
    }
  }

  .outside-card {
    top: 1000px;
  }

  .animated-card {
    transition: all .5s;
  }

  .card {
    position:absolute;
    width: 100%;
    height: 100%;
    -webkit-background-size: 168px 248px;
    background-size: 168px 248px;
    border-radius: 16px;
    border: 4px solid transparent;
    background-position: -4px;

    &.player__card{
      top: 20px;
    }
    &.master-card {
      border: 4px solid yellow;
    }

    &.not-animated-hide {
      transform: translate(0, 1500px)!important;
    }
  }
 .open-card {
    .shadow {
      box-shadow: -3px -3px 30px rgba(0,0,0, .1);
    }
  }
  .hidden-card {
    background: url('../img/imagio_cover.png');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 27px;
  }
}

.result, .finished {
  .button {
    right: 10px;
    bottom: 10px;
    position: absolute;
  }

  .cards-wrapper {
    margin-top: 90px;
    &.small-cards {
      margin-top: 50px;

      .card {
        width: 140px;
        height: 200px;
        background-size: 140px 200px;
      }
    }
  }
}

.open-cards {
  display: inline-block;
  vertical-align: middle;
  margin-left: 200px;
  .shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  }

  .players{
    left: 50%;
    top: -60px;
    right: auto;
    margin-left: -12px;
    transform: translate(-50%);
    white-space: nowrap;
    .player__img-wrap{
      position: relative;
      width: 22px;
      display: inline-block;
      &:first-child{
        right: 0;
      }
    }
  }
  &.split-cards-by-5,
  &.split-cards-by-6 {
    width: 50%;
    .cc_4, .cc_5, .cc_6, .cc_7, .cc_8, .cc_9, .cc_10{
      .players{
        top: auto;
        bottom: -60px;
      }
    }
  }
  &.split-cards-by-7,
  &.split-cards-by-8 {
    width: 60%;
    .cc_5, .cc_6, .cc_7, .cc_8, .cc_9, .cc_10{
      .players{
        top: auto;
        bottom: -60px;
      }
    }
  }
  &.split-cards-by-9,
  &.split-cards-by-10 {
    width: 80%;
    .cc_6, .cc_7, .cc_8, .cc_9, .cc_10{
      .players {
        top: auto;
        bottom: -60px;
      }
    }
  }

}


.batch-cards {
  white-space: nowrap;
  width: 85%;
  transform: translate3d(-40px, 0, 0);
  margin: 0 auto;
  position: absolute;
  left: 5%;

  &__card {
    width: 174px;
    height: 260px;
    border-radius: 16px;
    display: inline-block;

    position: absolute;

    &:nth-child(1) {
      transform: translate3d(-80%, 0, 0) rotate(-7deg);
      background: url('../img/cards/1_city.jpg') 0 0 no-repeat;
      background-position: center;
      background-size: 180px 265px;
      -webkit-background-size: 180px 265px;
      &.hidden {
        transform: translate3d(0, 0, 0) rotate(-7deg);
      }
    }

    &:nth-child(2) {
      transform: translate3d(-40%, -10px, 0) rotate(-3deg);
      background: url('../img/cards/2_fish_from_another_district.jpg') 0 0 no-repeat;
      background-position: center;
      background-size: 180px 265px;
      -webkit-background-size: 180px 265px;
      &.hidden {
        transform: translate3d(0, -10px, 0) rotate(-3deg);
      }
    }

    &:nth-child(3) {
      transform: translate3d(0px, -17px, 0);
      background: url('../img/cards/3_super_idea.jpg') 0 0 no-repeat;
      background-position: center;
      background-size: 180px 265px;
      -webkit-background-size: 180px 265px;
      &.hidden {
        transform: translate3d(0, -17px, 0);
      }
    }

    &:nth-child(4) {
      transform: translate3d(40%, -10px, 0) rotate(4deg);
      background: url('../img/cards/4_girl_with_ear_flower.jpg') 0 0 no-repeat;
      background-position: center;
      background-size: 180px 265px;
      -webkit-background-size: 180px 265px;
      &.hidden {
        transform: translate3d(0, -10px, 0) rotate(4deg);
      }
    }

    &:nth-child(5) {
      transform: translate3d(80%, 0, 0) rotate(8deg);
      background: url('../img/cards/5_snow_toy.jpg') 0 0 no-repeat;
      background-position: center;
      background-size: 180px 265px;
      -webkit-background-size: 180px 265px;
      &.hidden {
        transform: translate3d(0, 0, 0) rotate(8deg);
      }
    }

  }
}
