.button{
  min-width: 100px;
  background-color: rgba(255,255,255, .5);
  color: #ffffff;
  margin-top: 20px;
  border: solid 4px transparent;
  transition: border.1s;
  padding: 0 20px;
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  width: 440px;
  height: 101px;
  border-radius: 36px;
  box-shadow: 0 20px 68px 0 rgba(18, 35, 123, 0.6);
  background-color: #0F1741;
  margin-bottom: 30px;
  outline: none;
  font-family: "SF-Pro-Rounded-Black", sans-serif;
  line-height: 100px;
  text-decoration: none;
  &.disabled {
    opacity: .5;
  }
  &.focused {
    border: solid 4px #ffffff;
  }
  &:focus{
    border: solid 4px #ffffff;
  }
  &.mouseHover.activeLayer {
    &:hover {
      border: solid 4px #ffffff;
    }
  }
}

.button-rounded {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  color: #fff;
  font-size: 39px;
  line-height: 1.2;
  position: absolute;
  right: 0;
  z-index: $over-the-top;
  top: 50%;
  margin-top: -40px;
  box-shadow: 0 20px 68px 0 rgba(18, 35, 123, 0.6);
  svg {
    margin-top: 10px;
  }

  &:after{
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    height: 80px;
    width: 80px;
    border: 4px solid transparent;
    border-radius: 80px;
  }

  &.arrow {
    background-color: #0F1741;
    background-size: 40px;
  }

  &.check {
    background: #0F1741;
    width: $button-menu-size;
    height: $button-menu-size;
  }

  &.menu {
    width: $button-menu-size;
    height: $button-menu-size;
    position: absolute;
    z-index: $over-the-top;
    top: 15px;
    left: 15px;
    margin-top: 0px;
    background: #0F1741;
    background-size: 30px;
    svg {
      width: 30px;
      height: 30px;
      margin-top:14px;
    }
  }

  &.exit {
    width: $button-menu-size;
    height: $button-menu-size;
    position: absolute;
    z-index: $over-the-top;
    top: 15px;
    left: 15px;
    margin-top: 0px;
    background: #0F1741;
    background-position-x: 8px; // pawa
  }


  &.mouseHover.activeLayer {
    &:hover{
      &:after{
        border: 4px solid #ffffff;
      }
    }
  }
  &.focused{
    &:after{
      border: 4px solid #ffffff;
    }
  }

  &.rotate {
    transform: rotate(180deg);
  }


}
.button-rounded {
  &.menu:after {
    height: $button-menu-size;
    width: $button-menu-size;
  }

  &.check:after {
    height: $button-menu-size;
    width: $button-menu-size;
  }

  &.arrow:after {
    height: $button-arrow-size;
    width: $button-arrow-size;
  }

  &.exit:after {
    height: $button-menu-size;
    width: $button-menu-size;
  }
}

.finish_img {
  pointer-events: none;
  content: '';
  position: absolute;
  right: 40px;
  top: 60px;
  background: url("../img/finish.png") center no-repeat;
  -webkit-background-size: 100% 620px;
  background-size: 80px 560px;
  width: 80px;
  z-index: 0;
  height: 560px;
  border-radius: 0;
}

/* crutch */

.fullscreen, .results {
  .button-rounded {
    &.arrow {
      svg {
        path {
          width: 60px;
        }
        display: block;
        margin: 18px auto;
      }
    }
    &.menu {
      svg {
        margin: 14px auto;
        display: block;
      }
    }
  }
}
