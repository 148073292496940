.popup-wrapper {
  z-index: $popup;
  position: fixed;
  top: 0;
  width: $screen-width;
  height: $screen-height;
  .shadow {
    background: rgba(0, 0, 0, .5);
    position: absolute;
    width: $screen-width;
    height: $screen-height;
  }
  .popup-wrapper-inner {
    position: absolute;
    top: 50%;
    left: 50%;

    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 640px;

    box-sizing: border-box;
    background: #1B49A4;
    border-radius: 32px;

    padding: 90px 0;

    text-align: center;
    font-size: 36px;

    .button {
      min-width: 100px;
      width: 390px;
      height: 80px;
      margin: 20px auto;

      padding: 0 20px;

      box-shadow: 0 20px 68px 0 rgba(18, 35, 123, 0.6);
      background-color: #0F1741;
      color: #ffffff;
      border: solid 4px transparent;
      border-radius: 36px;
      transition: border .1s;

      font-family: "SF-Pro-Rounded-Black", sans-serif;
      font-size: 32px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 80px;
      outline: none;

      &.focused {
        border: solid 4px #ffffff;
      }

      &.mouseHover.activeLayer {
        &:hover {
          border: solid 4px #ffffff;
        }
      }
    }

    .title {
      margin: 35px auto;
      padding: 10px;
      &.ua {
        width: 80%;
      }
    }
  }
}
