.lobby-page {
  background: $background-color;
  text-align: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;

  .player-list-item {
    &.player {
      background-color: transparent;
      &.master {
        background-color: transparent;
      }
    }
  }

  .game-info {
    width: 75%;
    margin: 95px auto 25px;
    .text-info {
      display: inline-block;
      text-align: left;
      float: left;
      width: 70%;

      .game-id {
        font-size: 80px;
        font-weight: bold;
        letter-spacing: 10px;
        color: #ffffff;
        font-family: $bold;
        &__desc {
          font-family: "SF-Pro-Rounded-Black";
          font-size: 28px;
          max-width: 60%;
          margin: auto;
        }
      }
      .game-descr {
        font-size: 32px;
        line-height: 40px;
        padding-right: 35px;
        span {
          color: #DFC00C;
        }
      }
    }
    .svg-qr {
      display: block;
      font-size: 14px;
      width: 270px;
      height: 270px;
      overflow: hidden;
      svg {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        rect {
          fill: #FFF;
        }
        path {
          fill: #000;
        }
      }
    }
  }
}
