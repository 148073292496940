#root {
  height: $screen-height;
  width: $screen-width;
  background: $background-color;
  overflow: hidden;
  position: relative;
  .splash {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.start-page {
  background-size: 100%;
  height: 100%;
}

.status{
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0.5;
  font-size: 32px;
}

.game-status {
  margin-bottom: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -175px;
  width: 350px;
}

.onboarding-message {
  position:absolute;
  z-index: $over-the-top;

  top: 40px;
  right: 40px;
  padding: 37px 42px 48px;
  width: 300px;
  border-radius: 48px 0px 48px 48px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
  background-color: #5988a9;
  font-family: "SF-Pro-Rounded-Black";
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.timer {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  margin-left:-100px;
  .seconds {
    margin-top:25px;
    font-family: "SF-Pro-Rounded-Black";
    font-size: 44px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.45;
    letter-spacing: normal;
    color: #ffffff;
  }
}

.canvas-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: $background;

  left: 0;
  .small {
    width:200px;
    height:200px;
  }
  .p5Canvas {
    width:100%!important;
    height:100%!important;
  }
  pointer-events: none!important;
}

.fullscreen_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .arrow {
    right: 40px;
  }
}

.over-the-top-layer {
  position:absolute;
  z-index: $popup;
  top: 0;
  left: 0;
}

.filler {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  z-index: -1;
}

.game-id-overlap {
  position: absolute;
  bottom: 10px;
  right: 40px;
  font-size: 20pt;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 5px;
  opacity: 0.5;
}

.game-version-overlap {
  font-size: 10pt;
  opacity: 0.2;
  position: absolute;
  bottom: 0;
  right: 0;
}

.fullscreen {
  height: 100%;
}

.solid-background {
  background: $background-color-splash;
  background-size: 100%;
}

.splash {
    &__title {
      text-align: center;
      font-family: $bold;
      font-size: 56px;
      padding-bottom: 40px;
      position: relative;
      z-index: $foreground;

      &.logo {
        padding-bottom: 80px;
        padding-top: 0;
      }

      &_index {
        z-index: $foreground;
      }
    }

    &__box-wrap {
      position: absolute;
      left: 50%;
      top: -22px; // pawa
      z-index: $hidden;
      transform: translate(-50%, 0);
      padding-left: 60px;
    }

    &__box {
      width: 80px;
      height: 120px;
      border-radius: 20px;
      opacity: 0.9;
      position: relative;
      display: inline-block;

      &.one {
        /* fill/scarlet */
        left: 0;
        background: #D51F67;
        transform: rotate(-10deg);
      }

      &.two {
        /* fill/scarlet */
        left: -20px;
        background: #A24FDF;
        transform: rotate(30deg);
        z-index: 10;
        opacity: 0.7;
      }

      &.three {
        /* fill/scarlet */
        left: -40px;
        background: #FA7719;
        transform: rotate(-10deg);
      }

      &.four {
        /* fill/scarlet */
        left: -60px;
        background: #00A4FF;
        transform: rotate(14deg);
        opacity: 0.8;
      }
    }
}

img.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
}

.tint {
  transition: opacity .15s ease-in-out;
  background: rgba(0, 0, 0, .5);
}


@keyframes rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}
