@font-face {
  font-family: 'SF-Pro-Rounded-Regular';
  font-display: swap;
  src: url('/fonts/SFRounded-Ultralight-reg.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'SF-Pro-Rounded-Bold';
  font-display: swap;
  src: url('/fonts/SFRounded-Ultralight-bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'SF-Pro-Rounded-Black';
  font-display: swap;
  src: url('/fonts/SFRounded-Ultralight-black.ttf') format('truetype');
  font-weight: 900;
}


/*
@font-face {
  font-family: 'SF-Pro-Rounded-Regular';
  src: url('//s9.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.eot');
  src: url('//s1.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.eot?#iefix') format('embedded-opentype'),
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.otf')   format('opentype'),
       url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.svg')   format('svg'),
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.ttf')   format('truetype'),
       url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.woff')  format('woff'),
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'SF-Pro-Rounded-Bold';
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.eot');
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.eot?#iefix') format('embedded-opentype'),
  url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.otf') format('opentype'),
  url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.svg') format('svg'),
  url('//s8.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.ttf') format('truetype'),
  url('//s1.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.woff') format('woff'),
  url('//s8.vcdn.biz/static/93833061/SF-Pro-Rounded-Bold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'SF-Pro-Rounded-Black';
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.eot');
  src: url('//s5.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.eot?#iefix') format('embedded-opentype'),
       url('//s3.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.otf') format('opentype'),
       url('//s2.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.svg') format('svg'),
       url('//s1.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.ttf') format('truetype'),
       url('//s6.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.woff') format('woff'),
       url('//s9.vcdn.biz/static/93833061/SF-Pro-Rounded-Black.woff2') format('woff2');
  font-weight: 900;
}

*/
