$background-color: linear-gradient(240.64deg, #2E648B 0%, #397CAD 100%);
$background-color-splash: linear-gradient(-35deg, #196BCB 10%, #12237B 100%);

$animationSpeed: 1s;

// fonts
$bold: "SF-Pro-Rounded-Bold", Arial, sans-serif;
$regular: "SF-Pro-Rounded-Regular", Arial, sans-serif;
$black: "SF-Pro-Rounded-Black", Arial, sans-serif;

$screen-width: 1280px;
$screen-height: 720px;

// scale
$scale-screen-threshold: 1281px;
$scale-factor: 1.5;

// layers
$hidden:    -1;
$background: 0;
$foreground: 1;
$over-the-top: 15;
$popup: 16;
$background-top: -1;
$background-bottom: -2;


$button-menu-size: 60px;
$button-arrow-size: 80px;

// opacity background cards

$pink: 1;
$orange: 1;
$violet: 0.67;
$blue: 0.78;
