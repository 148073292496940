.show-score{
  height: 620px;
  width: 96%;
  position: absolute;
  top: 50px;
}

.show-results {
  width: 100%;
  .button-arrow {
    right: 51px;
    left: auto;
  }
}

.results {
  height: 100%;
  background: $background-color;
  .button {
    margin: auto;
  }
}
