.player {
  display: inline-block;
  margin: 10px 0;
  background-color: inherit;
  &:after{
    content: '';
    height: 100%;
    vertical-align: middle;
  }
  &__img-wrap{
    display: inline-block;
  }
  &__img{
    width: 41px;
    height: 41px;
    border-radius: 50%;
    border: 2px solid yellow;
    display: inline-block;
    vertical-align: middle;
    background-size: 41px;

    &.active{
      width: 70px;
      height: 70px;
      border-radius: 70px;
      background-size: 70px;
    }
  }

  &__name{
    display: inline-block;
    vertical-align: middle;
    color: #ccc;
    margin-left: 10px;
  }
  &__results{
    text-align: left;
    width: 430px;
    // position: absolute;
    // top: 40px;
    // left: 40px;
    // min-width: 100%;
  }
  &__result{
    position: relative;
    // display: inline-block;
    // vertical-align: bottom;
    // height: 100%;
    min-width: 60px;
    margin-right: 15px;
    .player__img{
      // position: absolute;
      left: 3px;
      bottom: 3px;
    }
  }
  &__score{
    position: absolute;
    bottom: 100%;
    width: 100%;
    text-align: center;
  }
  &__bar{
    // position: absolute;
    left: 0;
    bottom: 0;
    // width: 60px;
    border-right: 60px;
    border-radius: 60px;
    min-width: 60px;


    // width: 51px;
    border-right: 101px;
    border-radius: 143px;
    min-width: 51px;
  }
 }
.association{
  margin-top: 30px;
  margin-bottom: 30px;
}

.players{
  position: absolute;
  right: 10px;
  top: 10px;
  &.more-than-limit {
    .player {
      margin: 0;
      padding: 0;
      position:relative;
      width: 30px;

      &.active {
        width: 45px;
      }
    }
  }
}


.players-sidebar {
  position:absolute;
  z-index: $over-the-top;

  left: 20px;
  padding: 0 10px 0 10px;
  white-space: nowrap;
  overflow: hidden;
  top:50%;
  width: 250px;
  transform: translateY(-50%);
  color: #fff;

  &.full {
    width: 90%;
    padding-right: 20px;
    .player-list-item {
      &.finished {
        opacity: 1;
      }

      .player-id {
        width: 50%;
      }
      .player-score{
        background-color: #1f3099;
        text-shadow: 0 0 3px #1f2327;
        color: #fff;

      }
    }

  }
  .player-list-item {
    border-radius: 50px;
    display: block;
    text-align: left;
    font-size: 20px;
    font-family: $bold;
    position: relative;
    padding-right: 10px;
    background-color: rgba(#0f1741, 0.4);
    min-width: 190px;
    max-width: 97.5%;
    width: 190px;
    transition: all 1s;

    &.master {
      background-color: #ffffff;
      color: #0f1741;

    }

    &.removed {
      filter: grayscale(100%);
      color: gray;
    }
    &.vote, &.move {
      opacity: 0.12;
    }
    .player-id {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      margin: 0 0 0 15px;
      text-overflow: ellipsis;
      width: 90px;
      vertical-align: middle;
    }

    .player-score {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      position: absolute;
      right: -1px;
      top: -1px;
      line-height: 46px;
      width: 47px;
      height: 47px;
      transition: all 0.3s;
      border-radius: 50%;
      &:after{
        content: '';
        height: 100%;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}

.result, .start {
  .players-sidebar > .player-list-item.player {
    opacity: 1;
    /* tmp */
    background: #000;

    &.master {
      border: 1px solid transparent;
    }
  }
}


.moving, .voting {
  .players-sidebar > .player-list-item.player {
    //opacity: .5;
    position:relative;

    &.move, &.vote, &.finished {
      opacity: 1;
    }
    /* tmp */
    background: #000;
  }
}

.animated {
  transition: all $animationSpeed ease-in-out;
}



.player-hist {
  position: relative;
  height: 390px;
  padding-bottom: 30px;
  text-align: center;
  padding-top: 100px;

  .player{
    &__img-wrap{
      height: 100px;
      width: 100px;
      vertical-align: top;
    }
    &__img{
      background-size: 100% !important;
      height: 100%;
      width: 100%;
      border: none;
    }
  }
  &__item {
    margin-bottom: 16px;
    display: block;
    width: 250px;
    font-size: 40px;
    height: 100px;
    text-align: right;
    background-color: #295782;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
    position: relative;
    white-space: nowrap;
    &:nth-child(1) {
        width: 670px;
    }
    &:nth-child(3) {
        width: 760px;
    }
    &:nth-child(2) {
      width: 920px;
      font-size: 72px;
      height: 143px;
      line-height: 143px;
      .player{
        &__img-wrap{
          height: 140px;
          width: 140px;
        }
        &__img{
          background-size: 100% !important;
          height: 100%;
          width: 100%;
          vertical-align: top;
        }
      }

      .player-hist{
        &__username {
          font-size: 82px;
        }
      }
    }
  }
  &__score{
    line-height: 100px;
    display: inline-block;
    margin-right: 30px;
  }
  &__username{
    position: absolute;
    left: 104%;
    font-size: 32px;
    top: 50%;
    transform: translate(0,-50%);
    max-width: 425px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
